<template>
  <Container v-if="productDetail" :style="{ padding: 0 }">
    <div class="h-15 px-6 bg-black flex justify-between text-white items-center">
      <div class="text-xl flex items-center">LOT# {{ productDetail.lot }} |
        <div v-if="!productDetail.order" class="ml-1">
          <span class="text-primary">
            {{ salesStatus[productDetail.salesStatus].label }}
          </span>
        </div>
        <div v-else class="ml-1">
          <span v-if="orderInfo.status === 'none'" class="text-primary">-</span>
          <span v-else class="text-primary">
            {{ orderStatus[orderInfo.status].label }}
          </span>
        </div>
      </div>
      <div class="text-sm">등록일 : {{ productDetail.createdAt || '-' }}</div>
    </div>
    <div class="py-14 px-10">
      <div>
        <div class="font-bold text-xl">기본 정보</div>
        <div class="mt-2 text-sm">
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">
                작품명-한글
              </div>
              <div>{{ productDetail.titleKr || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">
                작품명-English
              </div>
              <div>{{ productDetail.titleEn || '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">
                작가명-한글
              </div>
              <div>{{ productDetail.artistNameKr || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">
                작가명-English
              </div>
              <div>{{ productDetail.artistNameEn || '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">카테고리</div>
              <div>
                {{ categoryDepth1Obj[productDetail.categoryDepth1Id] }} >
                {{ categoryDepth2Obj[productDetail.categoryDepth2Id] }}
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">태그(Tag)</div>
              <div>{{ productDetail.tags || '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">
                  제작시기
                </div>
                <div>
                  {{ productDetail.period || '-' }}
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">제작년도</div>
                <div>{{ productDetail.makeYear || '-' }}</div>
              </div>
            </div>
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">국가</div>
                <div>{{ productDetail.country || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">에디션(n/n)</div>
                <div>{{ productDetail.edition || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">사이즈</div>
                <div>
                  {{ productDetail.size || '-' }}
                  <span v-if="productDetail.size"> cm</span>
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">보증서유무</div>
                <div>
                  {{ productDetail.certificate ? '보증서 있음' : '보증서 없음' }}
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">상태</div>
                <div>{{ productDetail.status || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">이벤트코드</div>
                <div>{{ productDetail.eventCode || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">재질</div>
              <div>{{ productDetail.medium || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">서명정보</div>
              <div>{{ productDetail.signature || '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row" style="min-height: 130px">
            <div class="flex">
              <div class="InfoTable__labelColumn">소장이력</div>
              <div>{{ productDetail.ownHistory || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">수록처</div>
              <div>{{ productDetail.recordedPlace || '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row" style="min-height: 130px">
            <div class="flex">
              <div class="InfoTable__labelColumn">전시이력</div>
              <pre class="font-sans whitespace-pre-wrap">{{
                  productDetail.exhibitionHistory || '-'
                }}</pre>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">작가정보</div>
              <pre class="font-sans whitespace-pre-wrap">{{
                  productDetail.artistInfo || '-'
                }}</pre>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row" style="min-height: 130px">
            <div class="flex">
              <div class="InfoTable__labelColumn">상세설명</div>
              <pre class="font-sans whitespace-pre-wrap">{{
                  productDetail.description || '-'
                }}</pre>
            </div>
          </div>
          <div class="ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">상품 이미지</div>
              <div
                v-if="productDetail.productImageList"
                class="inline-flex flex-wrap"
              >
                <div
                  v-for="(item, index) in productDetail.productImageList"
                  :key="index"
                  class="border w-25 h-25 mr-2"
                  :style="{
                    background: `url(${item.url}) no-repeat center`,
                    backgroundSize: 'cover',
                  }"
                ></div>
              </div>
              <div v-if="!productDetail.productImageList.length">
                등록된 이미지가 없습니다
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="font-bold text-xl">판매 정보</div>
        <div class="mt-2 text-sm">
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">판매옵션</div>
                <div>
                  <span v-if="productDetail.enableBid">Bid Now</span>
                  <span v-else-if="productDetail.buynowOnly">Buy now</span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">시작가</div>
                <div v-money="productDetail.startPrice"></div>
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">Buy Now 금액</div>
              <div v-money="productDetail.buynowPrice"></div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="grid">
              <div class="flex col">
                <div class="InfoTable__labelColumn">판매기간</div>
                <template v-if="productDetail.buynowOnly">
                  <div v-if="productDetail.startDate">
                    {{ `${formatDate(productDetail.startDate)}` }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-else>
                  <div v-if="productDetail.startDate && productDetail.endDate">
                    {{
                      `${formatDate(productDetail.startDate)} ~ ${formatDate(productDetail.endDate)}`
                    }}
                  </div>
                  <div v-else>-</div>
                </template>
              </div>
            </div>
            <div class="grid-cols-2 grid">
              <div class="flex">
                <div class="InfoTable__labelColumn">구매제한</div>
                <div>
                  {{ productDetail.buyLimit ? '있음' : '없음' }}
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">과세/면세</div>
                <div>
                  {{ productDetail.taxFree ? '면세' : '과세' }}
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">배송방법</div>
              <div>
                {{
                  shippingTypeOptions.find((option) => option.value === productDetail.shippingTypeId)?.label || '-'
                }}
              </div>
            </div>
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">배송비</div>
                <div v-money="productDetail.shippingFee"></div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">배송비 부담</div>
                <div>
                  {{
                    productDetail.feePrepaidShipping
                      ? '구매자 부담'
                      : '판매자 부담'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="flex items-center">
          <strong class="font-bold text-xl mr-4">판매 상태</strong>
          <div v-if="!productDetail.order">
            <mark :class="`sale-status__label ${salesStatus[productDetail.salesStatus].colorClass}`">
              {{ salesStatus[productDetail.salesStatus].label }}
            </mark>
          </div>
          <div v-else>
            <span v-if="orderInfo.status === 'none'" class="text-xs">-</span>
            <mark v-else :class="`status__label ${orderStatus[orderInfo.status].colorClass}`">
              {{ orderStatus[orderInfo.status].label }}
            </mark>
          </div>
        </div>
        <div class="mt-2 text-sm">
          <p class="text-sm border-b border-gray-400 leading-10 h-10 font-semibold">
            결제 정보
          </p>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border">
            <div class="grid grid-cols-1">
              <div class="flex">
                <div class="InfoTable__labelColumn">판매가(낙찰가)</div>
                <div>
                  {{ convertNumWithComma(orderInfo.capitalPrice) }}
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">구매수수료</div>
                <div>
                  {{ convertNumWithComma(orderInfo.totalSupplyFee) }}
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">배송료</div>
                <div>
                  {{ convertNumWithComma(orderInfo.totalShippingFee) }}
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1">
              <div class="flex">
                <div class="InfoTable__labelColumn">결제금액(결제수단)</div>
                <div>
                  {{ convertNumWithComma(orderInfo.totalPrice + orderInfo.totalShippingFee) }}
                  {{ orderInfo.payMethod === 'CARD' ? '(신용카드)' : orderInfo.payMethod === 'VBANK' ? '(가상계좌)' : ''}}
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">상태</div>
                <div>{{ paymentStatus }}</div>
              </div>
            </div>
          </div>

<!--          <div class="grid grid-cols-2 ProductInfoTable__row with-border">-->
<!--            <div class="grid grid-cols-2">-->
<!--              <div class="flex">-->
<!--                <div class="InfoTable__labelColumn">결제금액(결제수단)</div>-->
<!--                <div>-->
<!--                  {{ convertNumWithComma(orderInfo.totalPrice) }}-->
<!--                  {{ orderInfo.payMethod === 'CARD' ? '(신용카드)' : orderInfo.payMethod === 'VBANK' ? '(가상계좌)' : ''}}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flex">-->
<!--                <div class="InfoTable__labelColumn">배송료</div>-->
<!--                <div>-->
<!--                  {{ convertNumWithComma(orderInfo.totalShippingFee) }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="flex">-->
<!--              <div class="InfoTable__labelColumn">상태</div>-->
<!--              <div>{{ paymentStatus }}</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="mt-2 text-sm">
          <p class="text-sm border-b border-gray-400 leading-10 h-10 font-semibold">
            구매자 정보
          </p>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border">
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">구매자(낙찰자) 이름</div>
                <div>{{ orderInfo.name || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">전화번호</div>
                <div>{{ orderInfo.buyerMobile || '-' }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">ID (이메일)</div>
              <div>{{ orderInfo.buyerEmail || '-' }}</div>
            </div>
          </div>
        </div>
        <div class="mt-2 text-sm">
          <p class="text-sm border-b border-gray-400 leading-10 h-10 font-semibold">
            배송 정보
          </p>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border">
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">배송지명</div>
                <div>{{ orderInfo.addressName || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">받으실 분 이름</div>
                <div>{{ orderInfo.name || '-' }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">전화번호</div>
              <div>{{ orderInfo.tel1 ? `${orderInfo.tel1}` : '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border">
            <div class="flex">
              <div class="InfoTable__labelColumn">주소</div>
              <div>{{ orderInfo.address1 ? `${orderInfo.address1} ${orderInfo.address2} / ${orderInfo.postcode}` : '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border with-button">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">배송정보</div>
              <div>
                {{
                  orderInfo.shippingTypeId && orderInfo.trackingCode
                    ? `${orderInfo.shippingType.shippingTypeName} / ${orderInfo.trackingCode}`
                    : '-'
                }}
                {{
                  shippingInfo && shippingInfo.lastDetail
                    ? `/ ${shippingInfo.lastDetail.kind} (${shippingInfo.lastDetail.timeString})`
                    : ''
                }}
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">반품메모</div>
              <div>{{ orderInfo.returnMemo || '-' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="flex items-center">
          <strong class="font-bold text-xl mr-4">정산내역</strong>
        </div>
        <div class="mt-2 text-sm">
          <p class="text-sm border-b border-gray-400 leading-10 h-10 font-semibold">
            정산금액
          </p>
          <div class="ProductInfoTable__row with-border">
            <div class="grid grid-cols-4">
              <div class="flex">
                <div class="InfoTable__labelColumn">결제금액(배송료)</div>
                <div>
                  {{ convertNumWithComma(orderInfo.totalPrice + orderInfo.totalShippingFee) }}
                  ({{ convertNumWithComma(orderInfo.totalShippingFee) }})
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">판매수수료</div>
                <div>
                  {{ convertNumWithComma(orderInfo.totalFee) }}
                  ({{ Number((orderInfo.feeRate * 100).toFixed(2)) }}%)
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">결제(PG)수수료</div>
                <div>
                  {{ convertNumWithComma(orderInfo.pgFee) }}
                  ({{ orderInfo.payMethod === 'CARD' ? '2.97%, 신용카드' : '가상계좌' }})
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">수수료합계</div>
                <div>
                  {{ convertNumWithComma(orderInfo.totalFee + orderInfo.pgFee) }}
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border">
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">정산금액</div>
                <div>
                  <b>{{ convertNumWithComma(orderInfo.settlementAmount) }}</b> (결제금액 - 수수료합계)
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 text-sm">
          <p class="text-sm border-b border-gray-400 leading-10 h-10 font-semibold">
            지급정보
          </p>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border">
            <div class="grid grid-cols-2">
              <div class="flex">
                <div class="InfoTable__labelColumn">예금주</div>
                <div>{{ productDetail.paymentInfo?.companyName }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">지급은행</div>
                <div>{{ productDetail.paymentInfo?.bankName }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">계좌번호</div>
              <div>{{ productDetail.paymentInfo?.bankAccount }}</div>
            </div>
          </div>
        </div>
        <div class="mt-2 text-sm">
          <p class="text-sm border-b border-gray-400 leading-10 h-10 font-semibold">
            정산상태
          </p>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border with-button">
            <div class="grid grid-cols-2">
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">상태</div>
                <div>
                  {{
                    orderInfo.settlementStatus === 'waiting'
                      ? '정산대기'
                      : orderInfo.settlementStatus === 'complete'
                      ? '정산완료'
                      : '-'
                  }}
                </div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">구매확정일자</div>
                <div>
                  {{
                    orderInfo.completeDate
                      ? formatDate(orderInfo.completeDate)
                      : '-'
                  }}
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2">
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">정산완료일자</div>
                <div>
                  {{
                    orderInfo.settlementStatus === 'waiting' || !orderInfo.settlementDate
                      ? '-'
                      : formatDate(orderInfo.settlementDate)
                  }}
                </div>
              </div>
              <div>
                <div v-if="orderInfo.settlementStatus === 'complete'" class="flex justify-end">
                  <button
                    class="border border-gray-300 h-7 w-32"
                    @click="printSpecification(orderInfo)"
                  >정산내역서</button>
                  <button
                    class="border border-gray-300 h-7 w-32 ml-2"
                    @click="printTaxSpecification"
                  >세금계산서</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="productDetail.productId" class="grid grid-cols-2 gap-x-14 mt-10">
        <div>
          <BidListTable :product-detail="productDetail" />
        </div>
        <div>
          <QnAListTable :product-detail="productDetail"></QnAListTable>
        </div>
      </div>
    </div>
    <form v-if="orderInfo.trackingCode" id="sh" name="sh" target="sh" action="https://info.sweettracker.co.kr/tracking/4" method="post" >
      <input type="hidden" name="t_key" value="3WKFb74IFW9LZ0n9yzYvDQ">
      <input type="hidden" name="t_code" :value="orderInfo.shippingType.shippingTypeCode" placeholder="택배사 코드">
      <input type="hidden" name="t_invoice" :value="orderInfo.trackingCode" placeholder="운송장 번호">
    </form>
  </Container>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import BidListTable from '@/views/sales/SalesDetail/BidListTable.vue';
import QnAListTable from '@/views/sales/SalesDetail/QnAListTable.vue';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';

export default defineComponent({
  name: 'SettlementDetail',
  components: {
    Container,
    BidListTable,
    QnAListTable,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const { salesStatus, orderStatus } = salesAndOrderStatus();
    const productDetail = ref<any>(null);
    const orderInfo = computed(() => {
      return productDetail.value.order || {};
    });
    const shippingInfo = ref(null);
    const productIdParam = ref<number>();
    const route = useRoute();

    const paymentStatus = computed(() => {
      if (orderInfo.value.status === 'deposit_wait') {
        return '입금대기중';
      } else if (orderInfo.value.payDate) {
        return `결제완료(${formatDatePattern(orderInfo.value.payDate, 'yyyy-MM-dd HH:mm')})`
      } else {
        return '-';
      }
    });

    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { shippingTypeOptions } = useShippingTypeOptions(true);

    onMounted(async () => {
      productIdParam.value = route.params.productId
        ? parseInt(route.params.productId as string)
        : undefined;

      if (!productIdParam.value) {
        alert('There is no product id');
      } else {
        // 저장된 상품정보를 가져온다.
        await fetchProductInfo(productIdParam.value);
      }
    });

    const fetchProductInfo = async (productId) => {
      try {
        const { data } = await partnerAPI.adminOrder.adminOrderSettlementInfo({
          productId,
        });

        console.log('data', data);
        productDetail.value = (data as any).data;
      } catch (error) {
        console.error(error);
        alert(getServerErrorMessage(error))
        window.close()
      }
      if (productDetail.value.order) {
        await fetchShippingInfo(productDetail.value.order.orderNo);
      }
    };

    const fetchShippingInfo = async (orderNo) => {
      const { data } = await partnerAPI.product.getShippingTrackingUsingGET1({
        ordernum: orderNo,
      });
      shippingInfo.value = (data as any).data;
    };

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    const printSpecification = (orderInfo) => {
      window.open(
        `/etc/settlement/specification?orderId=${orderInfo.orderId}`,
        '',
        'width=1050, height=1500, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no'
      );
    };

    const printTaxSpecification = () => {
      alert('준비중입니다.');
    };

    return {
      paymentStatus,
      productIdParam,
      productDetail,
      orderInfo,
      shippingInfo,
      salesStatus,
      orderStatus,
      fetchProductInfo,
      formatDate,
      formatDatePattern,
      categoryDepth1Obj,
      categoryDepth2Obj,
      shippingTypeOptions,
      printTaxSpecification,
      printSpecification,
      convertNumWithComma,
    };
  },
});
</script>

<style lang="scss" scoped>
.ProductInfoTable__row {
  border-bottom: 1px solid #ddd;
  padding: 0.65rem 0;
  &:last-child {
    border-bottom: none;
  }
  &.with-border {
    border-bottom: 1px solid #ddd;
  }
  &.with-button {
    padding: 5px 0;
  }
}

.InfoTable__labelColumn {
  color: #999;
  width: 144px;
  flex-shrink: 0;
}
@import "../../../assets/style/label";
</style>
