
import { computed, defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import BidListTable from '@/views/sales/SalesDetail/BidListTable.vue';
import QnAListTable from '@/views/sales/SalesDetail/QnAListTable.vue';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';

export default defineComponent({
  name: 'SettlementDetail',
  components: {
    Container,
    BidListTable,
    QnAListTable,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const { salesStatus, orderStatus } = salesAndOrderStatus();
    const productDetail = ref<any>(null);
    const orderInfo = computed(() => {
      return productDetail.value.order || {};
    });
    const shippingInfo = ref(null);
    const productIdParam = ref<number>();
    const route = useRoute();

    const paymentStatus = computed(() => {
      if (orderInfo.value.status === 'deposit_wait') {
        return '입금대기중';
      } else if (orderInfo.value.payDate) {
        return `결제완료(${formatDatePattern(orderInfo.value.payDate, 'yyyy-MM-dd HH:mm')})`
      } else {
        return '-';
      }
    });

    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { shippingTypeOptions } = useShippingTypeOptions(true);

    onMounted(async () => {
      productIdParam.value = route.params.productId
        ? parseInt(route.params.productId as string)
        : undefined;

      if (!productIdParam.value) {
        alert('There is no product id');
      } else {
        // 저장된 상품정보를 가져온다.
        await fetchProductInfo(productIdParam.value);
      }
    });

    const fetchProductInfo = async (productId) => {
      try {
        const { data } = await partnerAPI.adminOrder.adminOrderSettlementInfo({
          productId,
        });

        console.log('data', data);
        productDetail.value = (data as any).data;
      } catch (error) {
        console.error(error);
        alert(getServerErrorMessage(error))
        window.close()
      }
      if (productDetail.value.order) {
        await fetchShippingInfo(productDetail.value.order.orderNo);
      }
    };

    const fetchShippingInfo = async (orderNo) => {
      const { data } = await partnerAPI.product.getShippingTrackingUsingGET1({
        ordernum: orderNo,
      });
      shippingInfo.value = (data as any).data;
    };

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    const printSpecification = (orderInfo) => {
      window.open(
        `/etc/settlement/specification?orderId=${orderInfo.orderId}`,
        '',
        'width=1050, height=1500, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no'
      );
    };

    const printTaxSpecification = () => {
      alert('준비중입니다.');
    };

    return {
      paymentStatus,
      productIdParam,
      productDetail,
      orderInfo,
      shippingInfo,
      salesStatus,
      orderStatus,
      fetchProductInfo,
      formatDate,
      formatDatePattern,
      categoryDepth1Obj,
      categoryDepth2Obj,
      shippingTypeOptions,
      printTaxSpecification,
      printSpecification,
      convertNumWithComma,
    };
  },
});
